import React from 'react';

const MoneyLogo = () => {
    return (
        <svg width="130" height="125" viewBox="0 0 130 125" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M34.3518 54.5302V71.0719C34.3518 72.2916 33.3806 73.2765 32.1864 73.2765C30.996 73.2765 30.021 72.2878 30.021 71.0719V58.6249L25.6108 63.7878C25.2858 64.1666 24.821 64.3825 24.3259 64.3825C23.8309 64.3825 23.3623 64.1628 23.041 63.784L18.6384 58.5908V71.0719C18.6384 72.2916 17.6672 73.2765 16.473 73.2765C15.2826 73.2765 14.3076 72.2878 14.3076 71.0719V54.5302C14.3076 53.0378 15.498 51.8257 16.9643 51.8257H17.0739C17.8902 51.8257 18.646 52.2045 19.1524 52.856L24.3448 59.5568L29.628 52.8408C30.1344 52.1969 30.8864 51.8257 31.6951 51.8257C33.1614 51.8219 34.3518 53.0378 34.3518 54.5302Z" fill="black"/>
            <path d="M50.3706 51.5759C44.1956 51.5759 39.3584 56.4244 39.3584 62.61C39.3584 68.8297 44.0898 73.5229 50.3706 73.5229C56.6628 73.5229 61.4131 68.8335 61.4131 62.61C61.4093 56.4282 56.5607 51.5759 50.3706 51.5759ZM50.3706 69.4206C46.6822 69.4206 43.9046 66.4699 43.9046 62.5532C43.9046 58.6971 46.7465 55.682 50.3706 55.682C54.0136 55.682 56.8668 58.7009 56.8668 62.5532C56.8668 66.4661 54.0703 69.4206 50.3706 69.4206Z" fill="black"/>
            <path d="M86.0944 53.9319V70.6326C86.0944 72.091 84.9305 73.2728 83.4982 73.2728C82.6781 73.2728 81.8996 72.8713 81.4121 72.2008L71.5677 58.6326L71.6962 71.1326C71.7037 71.7046 71.4883 72.2387 71.0953 72.644C70.7023 73.0493 70.177 73.2728 69.6177 73.2728C68.4726 73.2728 67.543 72.3258 67.543 71.1554V54.4925C67.543 53.019 68.7145 51.822 70.1619 51.822C70.9819 51.822 71.768 52.2235 72.2592 52.894L82.0659 66.269L81.9639 53.947C81.9601 53.3788 82.1717 52.8485 82.5648 52.447C82.954 52.0455 83.4755 51.822 84.031 51.822C85.1685 51.822 86.0944 52.769 86.0944 53.9319Z" fill="black"/>
            <path d="M108.766 71.303C108.766 72.4052 107.912 73.2764 106.861 73.2764H94.8021C93.8649 73.2764 93.1016 72.4999 93.1016 71.5416V53.5605C93.1016 52.606 93.8649 51.8257 94.8021 51.8257H106.861C107.912 51.8257 108.766 52.6969 108.766 53.7689C108.766 54.8711 107.912 55.7461 106.861 55.7461H97.4928V60.5037H103.759C104.809 60.5037 105.667 61.3749 105.667 62.4469C105.667 63.5189 104.809 64.3901 103.759 64.3901H97.4928V69.3636H106.861C107.912 69.3598 108.766 70.231 108.766 71.303Z" fill="black"/>
            <path d="M130 53.7955C130 54.1326 129.913 54.5151 129.747 54.8106L124.006 64.5303V64.5341C124.006 64.6098 124.003 64.6326 124.003 64.6629C123.451 80.8902 116.777 96.0455 105.202 107.367C93.5698 118.739 78.2305 125 62.0183 125C27.8215 125 0 96.9659 0 62.5038C0 28.0379 27.8215 0 62.0183 0C82.7578 0 102.035 10.3826 113.591 27.7765C113.928 28.2841 114.049 28.8939 113.928 29.4924C113.81 30.0947 113.47 30.6098 112.968 30.947C112.465 31.2917 111.86 31.4129 111.267 31.2917C110.67 31.1742 110.16 30.8295 109.82 30.322C99.1174 14.197 81.2462 4.57197 62.0221 4.57197C30.327 4.57197 4.5689 30.5568 4.5689 62.5C4.5689 94.4394 30.327 120.424 62.0221 120.424C77.0439 120.424 91.257 114.617 102.039 104.08C112.779 93.5795 118.965 79.4659 119.464 64.4205L113.792 54.7576C113.444 54.1402 113.451 53.4091 113.803 52.7955C114.158 52.1894 114.789 51.822 115.488 51.822H115.67C116.369 51.822 117.019 52.2083 117.367 52.822L121.807 60.7273L126.361 52.8068C126.705 52.2008 127.351 51.822 128.046 51.822C129.127 51.822 130 52.7083 130 53.7955Z" fill="black"/>
        </svg>
    );
};
export default MoneyLogo;
